<template>
  <div>

    <router-view class="main"></router-view>

  </div>
</template>

<script>
  export default {
    name:'layout',
    data() {
      return {
        
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>